<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">
      Kurikulum
    </div>
    <BCol class="p-0 d-flex">
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama"
          class="border-0"
          @input="handleSearch()"
        />
      </div>
      <BButton
        tag="router-link"
        to="/curriculum/create"
        variant="primary"
        class="ml-auto px-3"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 275px); overflow-y: scroll;"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            <div class="flex items-center">
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(level)="data">
            <div
              v-if="data.item.level === 'basic'"
              class="flex items-center basic"
            >
              Dasar
            </div>
            <div
              v-if="data.item.level === 'specific'"
              class="flex items-center specific"
            >
              Spesifik
            </div>
            <div
              v-if="data.item.level === 'mature'"
              class="flex items-center mature"
            >
              Pematangan
            </div>
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
                :to="{
                  name: $route.meta.routeEdit,
                  params: { id: data.item.id }
                }"
                tag="router-link"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/edit.svg"
                  alt="Komerce"
                  class="mr-[5px]"
                >
              </BButton>
              <BButton
                class="btn-icon mr-50"
                size="sm"
                variant="flat-warning"
              >
                <img
                  v-b-modal.modalDelete
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                  alt="Komerce"
                  @click="selectItem(data.item.id)"
                >
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>

    <ModalDelete
      :id-item="idItem"
      @deleted="getListCurriculums()"
    />
  </BCard>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komtimAxiosIns } from '@/libs/axios'
import { tableBiodata } from './config'
import ModalDelete from './ModalDelete.vue'

export default {
  components: {
    ModalDelete,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableBiodata,
      idItem: 0,
      filterSelected: [],
      listSkills: [],
    }
  },
  computed: {
    skill() {
      return this.filterSelected.join(',')
    },
  },
  mounted() {
    this.getListCurriculums()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 2
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListCurriculums() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}`
      const url = `v1/curriculums?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&status=registered&skill=${this.skill}&keyword=${this.keyword}`
        const url = `v1/curriculums?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListCurriculums()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    alertError(err) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text: err.response.data.meta.message,
          variant: 'danger',
        },
      }, 1000)
    },
  },
}
</script>

<style scoped lang="scss">
@import './style/Index.scss';
</style>
